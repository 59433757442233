<template>
  <!-- eslint-disable -->
  <div class="w-100 h-100">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <div v-if="action == 'editar'" class="">
        <b-row class="mx-0 w-100">
          <b-col md="6" lg="12" class="d-flex align-items-center justify-content-lg-center justify-content-start mb-lg-1">
            <feather-icon icon="MapPinIcon" size="20" class="" />
            <h4 class="mb-0 ml-1">Editar {{ title }}</h4>
          </b-col>
          <b-col md="6" lg="12" class="d-flex gap-2 justify-content-lg-center justify-content-end">
            <b-button
              @click="deleteAction"
              class="mr-1"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
            >
              Eliminar
            </b-button>
            <b-button
              @click="editLocal"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="primary"
            >
              Editar
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="12">
            <validation-observer #default="{ invalid }" ref="refEditLocal">
              <validation-provider
                #default="{ errors }"
                name="code"
                rules="requeridoE"
                class="d-flex flex-column"
              >
                <b-row class="mx-0 w-100 mb-2 d-flex align-items-center justify-content-center">
                  <b-col cols="12">
                    <b-form-group label="Codigo" label-for="code">
                      <b-form-input
                        id="code"
                        trim
                        :class="errors.length > 0 ? 'is-invalid' : null"
                        placeholder="code"
                        v-model="code"
                        class=""
                      />
                      <small
                        class="text-danger alert"
                        :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                        >{{ errors[0] }}</small
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="description"
                class="d-flex flex-column"
              >
                <b-row class="mx-0 w-100 d-flex align-items-center justify-content-center">
                  <b-col cols="12">
                    <b-form-group label="Descripción" label-for="justify">
                      <b-form-textarea
                        id="justify"
                        placeholder="Descripción"
                        rows="3"
                        max-rows="5"
                        v-model="description"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                        class=""
                      ></b-form-textarea>
                      <small
                        class="text-danger alert"
                        :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                        >{{ errors[0] }}</small
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-provider>
              <b-row class="mx-0 w-100 d-flex align-items-center justify-content-center">
                <b-col cols="12" class="mb-2"> </b-col>
                <b-col cols="12"> </b-col>
              </b-row>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
      <div v-if="action == 'nuevo'" class="w-100 h-100">
        <b-row class="mx-0 w-100">
          <b-col md="12" class="d-flex align-items-center justify-content-center mb-lg-1">
            <feather-icon icon="MapPinIcon" size="20" class="" />
            <h4 class="mb-0 ml-1">Agregar {{ title }}</h4>
          </b-col>
          <b-col md="12" class="d-flex gap-2 justify-content-center">
            <b-button
              @click="resetAdd"
              class="mr-1"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
            >
              Cancelar
            </b-button>
            <b-button
              @click="addLocal"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="primary"
            >
              Agregar
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="12">
            <validation-observer #default="{ invalid }" ref="refAddLocal">
              <validation-provider
                #default="{ errors }"
                name="code"
                rules="requeridoE"
                class="d-flex flex-column"
              >
                <b-row class="mx-0 w-100 mb-2 d-flex align-items-center justify-content-center">
                  <b-col cols="4">
                    <label for="">Codigo: </label>
                  </b-col>
                  <b-col cols="8">
                    <b-form-input
                      id="code"
                      trim
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      placeholder="code"
                      v-model="code"
                      class=""
                    />
                    <small
                      class="text-danger alert"
                      :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                      >{{ errors[0] }}</small
                    >
                  </b-col>
                </b-row>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="description"
                class="d-flex flex-column"
              >
                <b-row class="mx-0 w-100 d-flex align-items-center justify-content-center">
                  <b-col cols="4">
                    <label for="">Descripción: </label>
                  </b-col>
                  <b-col cols="8">
                    <b-form-textarea
                      id="justify"
                      placeholder="Descripción"
                      rows="3"
                      max-rows="5"
                      v-model="description"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      class=""
                    ></b-form-textarea>
                  </b-col>
                </b-row>
              </validation-provider>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
      <div v-if="action == ''" class="w-100 h-100">
        <div class="d-flex flex-column gap-3 justify-content-center align-items-center w-100 h-100">
          <feather-icon icon="MapPinIcon" size="40" class="mb-2" />
          <h4>Crear una nueva localización</h4>
          <h4>O selecciona uno del listado para</h4>
          <h4>crear una sublocalización</h4>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LocalizationService from '@/services/LocalizationService'
import SpecialityService from '@/services/SpecialityService'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  props: ['action', 'title', 'item', 'level', 'pro'],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required,
      code: '',
      description: '',
      showLoading: false,
      localSelect: null
    }
  },
  methods: {
    resetAdd() {
      this.$emit('reloadAdd', '')
    },
    resetForm() {
      this.code = ''
      this.description = ''
      if (this.action == 'nuevo') {
        this.$refs.refAddLocal.reset()
      } else {
        this.$refs.refEditLocal.reset()
      }
      //
    },
    async getLocal(id, level) {
      this.showLoading = true
      var resp = ''
      // if (level == -1) {
      //   resp = await SpecialityService.getSpecialityById(id, this.$store)
      // }
      if (level == 1) {
        resp = await LocalizationService.getLocation1(id, this.$store)
      } else if (level == 2) {
        resp = await LocalizationService.getLocation2(id, this.$store)
      } else if (level == 3) {
        resp = await LocalizationService.getLocation3(id, this.$store)
      } else {
        resp = await LocalizationService.getLocation4(id, this.$store)
      }
      if (resp != '') {
        this.localSelect = resp
        this.code = resp.data.code
        this.description = resp.data.description
      }
      this.showLoading = false
    },
    async addLocal() {
      this.$refs.refAddLocal.validate().then(async (success) => {
        if (success) {
          var resp = ''
          let datos = {
            code: this.code,
            description: this.description
          }
          console.log('this.localSelect', this.localSelect)
          console.log('this.item', this.item)
          console.log('this.level', this.level)
          if (this.level == -1) {
            console.log('this.pro',this.pro)
            // datos.speciality_id = parseInt(this.item)
            datos.project_id = parseInt(this.pro)
            resp = await LocalizationService.saveLocation1(datos, this.$store)
          } else if (this.level == 1) {
            datos.location_1_id = parseInt(this.item)
            resp = await LocalizationService.saveLocation2(datos, this.$store)
          } else if (this.level == 2) {
            datos.location_2_id = parseInt(this.item)
            resp = await LocalizationService.saveLocation3(datos, this.$store)
          } else {
            datos.location_3_id = parseInt(this.item)
            resp = await LocalizationService.saveLocation4(datos, this.$store)
          }
          console.log('datos', datos)
          if (resp != '') {
            if (resp.status) {
              this.$swal({
                title: 'Registrado',
                text: 'Localización añadida con éxito',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              this.$emit('reload')
              this.$emit('reloadAdd', '')
              this.$emit('setTitle', resp.data.code)
              this.code = resp.data.code
              this.description = resp.data.description
            } else {
              this.$swal({
                title: 'Error',
                text: 'Hubo un error al añadir los datos',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          }
        }
      })
    },
    async editLocal() {
      this.$refs.refEditLocal.validate().then(async (success) => {
        if (success) {
          console.log('this.level',this.level)
          var resp = ''
          let datos = {
            code: this.code,
            description: this.description
          }
          if (this.level == 1) {
            datos.project_id = this.localSelect.data.project_id
            console.log('this.localSelect', this.localSelect)
            resp = await LocalizationService.updateLocation1(this.item, datos, this.$store)
          } else if (this.level == 2) {
            console.log('this.localSelect', this.localSelect)
            datos.location_1_id = this.localSelect.data.location_1_id
            resp = await LocalizationService.updateLocation2(this.item, datos, this.$store)
          } else if (this.level == 3) {
            console.log('this.localSelect', this.localSelect)
            datos.location_2_id = this.localSelect.data.location_2_id
            resp = await LocalizationService.updateLocation3(this.item, datos, this.$store)
          } else {
            console.log('this.localSelect', this.localSelect)
            datos.location_3_id = this.localSelect.data.location_3_id
            resp = await LocalizationService.updateLocation4(this.item, datos, this.$store)
          }
          if (resp != '') {
            if (resp.status) {
              this.$swal({
                title: 'Actualizado',
                text:
                  this.level == -1 ? 'Localización ' : 'Sub-Localización ' + ' actualizada con éxito',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              this.$emit('reload')
              this.$emit('setTitle', resp.data.code)
              this.code = resp.data.code
              this.description = resp.data.description
            } else {
              this.$swal({
                title: 'Error',
                text: 'Hubo un error al editar los datos',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          } else {
            this.$swal({
              title: 'Error',
              text: 'Hubo un error al editar los datos',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        }
      })
    },
    async deleteAction() {
      console.log('lv',this.level)
      this.$swal({
        title: '¿Desea eliminar esta ' + (this.level == 1 ? 'localización ' : 'sub-localización ') + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteData()
        }
      })
    },
    async deleteData() {
      var resp = ''
      // if (this.level == -1) {
      //   resp = await SpecialityService.deleteSpeciality(this.item, this.$store)
      // } 
      if (this.level == 1) {
        resp = await LocalizationService.deleteLocation1(this.item, this.$store)
      } else if (this.level == 2) {
        resp = await LocalizationService.deleteLocation2(this.item, this.$store)
      } else if (this.level == 3) {
        resp = await LocalizationService.deleteLocation3(this.item, this.$store)
      } else {
        resp = await LocalizationService.deleteLocation4(this.item, this.$store)
      }
      if (resp != '') {
        if (resp.status) {
          this.$swal({
            title: 'Eliminado',
            text: this.level == -1 ? 'Localización ' : 'Sub-localización ' + ' eliminada con éxito',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
          this.code = ''
          this.description = ''
          this.$emit('reload')
          this.$emit('reloadAdd', '')
        } else {
          this.$swal({
            title: 'Error',
            text: 'Hubo un error al eliminar los datos',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        }
      } else {
        this.$swal({
          title: 'Error',
          text: 'Hubo un error al eliminar los datos',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
    }
  }
}
</script>

<style></style>
