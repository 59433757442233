<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row>
        <b-col cols="12" lg="8">
          <b-card class="h-100">
            <div class="d-flex flex-column flex-md-row gap-2 w-100">
              <b-form-group label="Proyecto" label-for="project" class="w-100">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="proyectos"
                  label="code"
                  input-id="project"
                  :reduce="(proyectos) => proyectos.id"
                  placeholder="Proyecto"
                  v-model="proyecto"
                  @input="selectProyect(proyecto)"
                  class="select-obra"
                  disabled
                >
                  <template v-slot:selected-option="option">
                    {{ option.code }} - {{ option.description }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.code }} - {{ option.description }}
                  </template>
                </v-select>
              </b-form-group>
              <div class="w-100 d-flex align-items-center justify-content-center">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="danger"
                  @click="createLocal(totalTree)"
                >
                  Crear Localización
                </b-button>
              </div>
            </div>
            <!-- <div class="w-100 d-flex align-items-center justify-content-center">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="danger"
                @click="createLocal(totalTree)"
              >
                Crear Localización
              </b-button>
            </div> -->
            <div class="w-100">
              <ejs-treeview
                :expandAll="true"
                id="treeview"
                ref="treeview"
                class="treeview"
                :fields="fields"
                :nodeSelected="nodeSelect"
              >
              </ejs-treeview>
            </div>
            <div
              v-if="totalTree.length > 0"
              class="w-100 d-flex align-items-center justify-content-center mt-1"
            >
              <b-button
                @click="createSubLocal(selectItem)"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="danger"
              >
                Crear Sub-Localización
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" lg="4">
          <b-card class="p-0 h-100">
            <Localizacion
              ref="location"
              @reloadAdd="reloadAdd"
              @setTitle="setTitle"
              @reload="selectProyect(proyecto)"
              :item="items"
              :action="action"
              :title="title"
              :level="level"
              :pro="pro"
            >
            </Localizacion>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations'
import ProjectsService from '@/services/ProjectsService'
import SpecialityService from '@/services/SpecialityService'
import LocalizationService from '@/services/LocalizationService'
import Localizacion from './localizacion.vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(TreeViewPlugin)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      showLoading: false,
      action: '',
      fields: { dataSource: [], id: 'id', text: 'code', child: 'node' },
      proyectos: [],
      especialidad: null,
      totalTree: [],
      selectItem: null,
      proyecto: JSON.parse(localStorage.getItem('project_id')),
      title: '',
      items: null,
      level: null,
      itemSelect: {},
      pro: null
    }
  },
  components: {
    vSelect,
    Localizacion
  },
  mounted() {
    this.selectProyect(this.proyecto)
    this.getData()
  },
  methods: {
    reloadAdd(act) {
      this.action = act
    },
    setTitle(tit) {
      this.title = tit
    },
    async showLocals(id) {},
    async getData() {
      const url = `?limit=100`
      const resp = await ProjectsService.getProyectos(url, this.$store)
      console.log('resp', resp)
      if (resp.status) {
        this.proyectos = resp.data.rows
        // if (this.proyectos.length > 0) {
        //   this.proyecto = this.proyectos[0].id
        // }
      }
    },
    async selectProyect(id) {
      this.showLoading = true
      this.action = ''
      this.level = -1
      const resp = await LocalizationService.getTreeview(id, this.$store)
      if (resp.status) {
        console.log('resp.data', resp.data)
        this.$refs.treeview.ej2Instances.fields.dataSource = resp.data
        this.totalTree = resp.data
      }
      this.showLoading = false
    },
    nodeSelect(args) {
      this.selectItem = args.nodeData
      console.log('this.selectItem', this.selectItem)
      const datos = args.nodeData.id.split('-')
      const title = args.nodeData.text
      this.passData(
        'editar',
        datos[0],
        title,
        parseInt(datos[1]) == 0 ? -1 : parseInt(datos[1]) ? parseInt(datos[1]) : 0
      )
      this.$refs.location.getLocal(
        datos[0],
        parseInt(datos[1]) == 0 ? -1 : parseInt(datos[1]) ? parseInt(datos[1]) : 0
      )
    },
    async passData(action, item, title, level, pro) {
      this.action = action
      if (item) {
        this.items = item
      }
      this.title = title
      this.level = level
      this.pro = pro
    },
    nodeClicked(args) {
      console.log('args', args)
    },
    async createLocal(items) {
      console.log('items', items)
      if (this.proyecto != null) {
        if (items.length > 0) {
          this.passData(
            'nuevo',
            this.proyecto,
            'localización ' + (items.length + 1),
            -1,
            this.proyecto
          )
          this.$refs.location.resetForm()
        } else {
          this.passData('nuevo', this.proyecto, 'localización 1', -1, this.proyecto)
        }
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Proyecto!',
          text: 'Seleccione un proyecto para empezar.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    createSubLocal(item) {
      if (item != null) {
        const datos = item.id.split('-')
        const espe = this.totalTree.map((e) => {
          if (e.level == 0) {
            return e.project_id
          }
        })
        console.log('item', item)
        console.log('espe[0]', espe[0])
        this.passData(
          'nuevo',
          datos[0],
          'sub-localización',
          parseInt(datos[1]) == 0 ? -1 : parseInt(datos[1]) ? parseInt(datos[1]) : 0,
          parseInt(espe[0])
        )
        this.$refs.location.resetForm()
      } else {
        this.$swal({
          icon: 'warning',
          title: 'Localización!',
          text: 'Seleccione una sub-localización para empezar.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '../../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css';
[dir] .e-treeview .e-list-item.e-active > .e-fullrow {
  background-color: #7367f0 !important;
  border-radius: 5px;
}

.e-treeview .e-list-item.e-active > .e-text-content,
.e-treeview .e-list-item.e-active > .e-text-content .e-list-text,
.e-treeview .e-list-item.e-active > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-active > .e-text-content .e-icon-expandable {
  color: white !important;
}
.e-treeview .e-list-item > .e-text-content .e-list-text {
  font-size: 15px;
  font-display: 'Montserrat', Helvetica, Arial, serif;
  color: #6e6b7b;
}
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
